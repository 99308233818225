import React from 'react'

export default function Footer(){
    
    return (
       
            <footer className="footer">
                <span>Copyright © 2021 H. Ayuntamiento de Lázaro Cárdenas, Desarrollado por Departamento de Informática</span>
            </footer>
      
    )
}

