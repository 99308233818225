import React from "react";
import { useQuery } from "@apollo/client";
import { GET_VISITAS } from "../../../gql/Visistas";
import { Row, Col, Nav, Tabs, Tab, Card } from "react-bootstrap";
const Atribuciones = ({ Clave }) => {
  //Apollo Graphl
  const { loading, error, data } = useQuery(GET_VISITAS, {
    variables: { noEmpleado: Clave },
  });

  if (loading) return <h1>Obteniendo información</h1>;
  if (error)
    return <div className="widget white-bg friends-group clearfix"></div>;
  const { getVisita } = data;
  if (getVisita.length === 0) return null;
  else
    return (
      <>
        {getVisita.map((visita) => (
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>Visita: {visita.visitas}</Card.Header>
                <Card.Body>
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Row>
                      <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="first">Descripción</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">Etapas</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={9}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: visita.descripcion,
                              }}
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: visita.etapas,
                              }}
                            />
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ))}
      </>
    );
};

export default Atribuciones;
