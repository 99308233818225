import { gql } from "@apollo/client";
export const GET_OFICINA = gql`
  query getOficinas($id: ID!) {
    getOficinas(id: $id) {
      id_dep
      nombre
      correo
      titular
      lada 
      tel1
      direccion
      colonia
    }
  }
`;
