import { gql } from "@apollo/client";
export const GET_VISITAS = gql`
  query visitas($noEmpleado: ID) {
    getVisita(noEmpleado: $noEmpleado) {
      id
      visitas
      descripcion
      etapas
    }
  }
`;
