import { gql } from "@apollo/client";

//Obtener perfil del empleado
export const GET_PROFILE = gql`
  query getProfile($id: ID!) {
    perfil: obtenerEmpleado(id: $id) {
      noEmpl
      CL_NOM
      CL_PAT
      CL_MAT
      PUESTO
      DEPARTAMENTO
      COMO
      FOTOGRAFIA
    }
  }
`;
