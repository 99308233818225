//Layouts
import Backend from "../layouts/Backend/Backend";

//Paginas Administrador
import EmpData from "../pages/EmpData";

const routes = [
  {
    path: "/perfil/:idempleado",
    layout: Backend,
    component: EmpData,
    exact: true,
  },
];

export default routes;
