import { useQuery } from "@apollo/client";
import React from "react";
import { GET_PROFILE } from "../../../gql/Employed";
const BasicData = ({ Clave }) => {
  //Apollo Graphl
  const { loading, error, data } = useQuery(GET_PROFILE, {
    variables: {
      id: Clave,
    },
  });

  console.log(data);

  if (loading) return <h1>Obteniendo información</h1>;

  if (error)
    return (
      <h1>La operación no puede ser completada por favor intente más tarde</h1>
    );

  const {
    CL_NOM,
    CL_PAT,
    CL_MAT,
    PUESTO,
    noEmpl,
    DEPARTAMENTO,
    COMO,
    FOTOGRAFIA,
  } = data.perfil;

  //extraer la información del empleado

  return (
    <div className="widget white-bg">
      <div className="padding-20 text-center">
        <img
          alt="Profile "
          width={140}
          className="rounded-circle mar-btm margin-b-10 circle-border "
          src={`${FOTOGRAFIA}`}
        />
        <p className=" font-500 margin-b-0">
          {CL_NOM} {CL_PAT} {CL_MAT}
        </p>
        <p className="text-muted">
          {PUESTO} DE {DEPARTAMENTO}
        </p>
        <p className="text-sm margin-b-0"></p>
        <hr />
        <ul className="list-unstyled margin-b-0 text-center row">
          <li className="col-5">No. Empleado: {noEmpl}</li>
          <li className="col-7">Atribución: {COMO}</li>
        </ul>
      </div>
    </div>
  );
};

export default BasicData;
