import { useQuery } from "@apollo/client";
import React from "react";
import { GET_OFICINA } from "../../../gql/Oficinas";

const Oficina = ({ siglas: id }) => {
  //Apollo
  const { loading, error, data } = useQuery(GET_OFICINA, {
    variables: {
      id: id,
    },
  });

  if (loading) return <h1>Obteniendo información</h1>;
  if (error)
    return <div className="widget white-bg friends-group clearfix"></div>;

  const { nombre, titular, correo, lada, tel1, direccion, colonia } =
    data.getOficinas;

  return (
    <div className="widget white-bg friends-group clearfix">
      <small className="text-muted">Dependencia </small>
      <p>{nombre}</p>
      <small className="text-muted">Jefe o Encargado </small>
      <p>{titular}</p>
      <small className="text-muted">Correo Electrónico </small>
      <p>{correo}</p>
      <small className="text-muted">Teléfono</small>
      <p>
        ({lada}) {tel1}{" "}
      </p>
      <small className="text-muted">Dirección</small>
      <p>
        {direccion}. {colonia}
      </p>
      <div className="map-box">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8833.641241543544!2d-102.20003784501623!3d17.95934938858807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d96870a7a973f%3A0x261e758a2af8d14f!2sH.%20Ayuntamiento%20de%20L%C3%A1zaro%20C%C3%A1rdenas!5e0!3m2!1ses-419!2smx!4v1631338647315!5m2!1ses-419!2smx"
          width="100%"
          height={150}
          frameBorder={0}
          style={{ border: 0 }}
          allowFullScreen
          title="profile map"
        />
      </div>
    </div>
  );
};

export default Oficina;
