import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import Oficina from "../components/profile/oficinaData/Oficina";
import BasicData from "../components/profile/basicData/BasicData";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_PROFILE } from "../gql/Employed";
import Atribuciones from "../components/profile/Atribuciones";

const EmpData = () => {
  const { idempleado } = useParams();
  const { data, loading, error } = useQuery(GET_PROFILE, {
    variables: {
      id: idempleado,
    },
  });
  if (loading) return <h1>Cargando información</h1>;
  console.log(data);
  if (error) return <h1>Por el momento nuestro sitio está en mantenimiento</h1>;
  if (data.perfil === null) return <h1>n</h1>;
  const { noEmpl, siglas } = data.perfil;
  return (
    <Row>
      <Col md="4">
        {/* Componente con la información del empleado (nombre, cargo, noempleado) */}
        <BasicData Clave={noEmpl} />
        {/* Aquí empieza la atribución de la dependencia */}
        <Oficina siglas={siglas} />
      </Col>
      <Col md="8">
        <Atribuciones Clave={noEmpl} />
      </Col>
      {/* Apartado para mostrar información adicional  */}
      <div className="col-12">
        {/* <Card>
          <Card.Header>Comisiones</Card.Header>
          <Card.Body></Card.Body>
        </Card> */}
      </div>
    </Row>
  );
};

export default EmpData;
